// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-categories-categories-tsx": () => import("./../../../src/templates/Categories/Categories.tsx" /* webpackChunkName: "component---src-templates-categories-categories-tsx" */),
  "component---src-templates-category-category-tsx": () => import("./../../../src/templates/Category/Category.tsx" /* webpackChunkName: "component---src-templates-category-category-tsx" */),
  "component---src-templates-home-home-tsx": () => import("./../../../src/templates/Home/Home.tsx" /* webpackChunkName: "component---src-templates-home-home-tsx" */),
  "component---src-templates-legal-legal-tsx": () => import("./../../../src/templates/Legal/Legal.tsx" /* webpackChunkName: "component---src-templates-legal-legal-tsx" */),
  "component---src-templates-post-post-tsx": () => import("./../../../src/templates/Post/Post.tsx" /* webpackChunkName: "component---src-templates-post-post-tsx" */)
}

